import tracking from "frontend/tracking";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { userAtom } from "state-atoms";

export function useSSE() {
  const user = useAtomValue(userAtom);
  const [forceReload, setForceReload] = useState(false);

  const isDisabled = process.env.SSE_SERVER_DISABLED === "true" || false;
  if (isDisabled) {
    return { forceReload };
  }

  const baseURL = process.env.SSE_SERVER_URL;
  if (!baseURL) {
    return { forceReload };
  }

  const deployVersion = process.env.DEPLOY_VERSION;
  if (!deployVersion) {
    return { forceReload };
  }

  useEffect(() => {
    if (forceReload) {
      tracking.trackEvent("utils", "forced_reload_on_old_client_version", deployVersion);
    }
  }, [forceReload]);

  useEffect(() => {
    if (!user?.account?.id) {
      return;
    }

    console.log("[SSE]", user.account.id, "current version", deployVersion);

    try {
      const events = new EventSource(baseURL + "/listen");
      events.onmessage = (event) => {
        handleEvent(JSON.parse(event.data));
      };
      // close connection
      return () => {
        events.close();
      };
    } catch (error) {
      console.error("[SSE] failed opening connection", error);
    }
  }, [user]);

  function handleEvent(event: any) {
    // if no account provided -> all events are handled
    // if account provided -> only events for this account are handled
    const shouldHandleAccount = !event.accountId || event.accountId === user?.account?.id;
    const shouldHandleUser = !event.userId || event.userId === user?.id;
    if (
      shouldHandleAccount &&
      shouldHandleUser &&
      (isVersionNeedUpdate(event.minVersion, deployVersion) || event.reload)
    ) {
      // force reload
      setForceReload(true);
    }
  }

  function isVersionNeedUpdate(version?: string, currentVersion?: string) {
    if (!version || !currentVersion) {
      return false;
    }
    const isCurrentGreaterOrEqual = compareVersion(normalizeVersion(currentVersion), normalizeVersion(version)) >= 0;
    return !isCurrentGreaterOrEqual;
  }

  function normalizeVersion(version: string) {
    // remove non-numeric characters
    version = version.replace(/[^0-9.]/g, "");
    // add missing patch version
    const [major, minor, patch = "0"] = version.split(".");
    return [major, minor, patch].join(".");
  }

  function compareVersion(currentVersion: string, version: string) {
    const [major, minor, patch] = version.split(".");
    const [currentMajor, currentMinor, currentPatch] = currentVersion.split(".");
    if (currentMajor > major) {
      return 1;
    }
    if (currentMajor < major) {
      return -1;
    }
    if (currentMinor > minor) {
      return 1;
    }
    if (currentMinor < minor) {
      return -1;
    }
    if (currentPatch > patch) {
      return 1;
    }
    if (currentPatch < patch) {
      return -1;
    }
    return 0;
  }

  return { forceReload };
}
