import { WriteTransaction } from "@workcanvascom/reflect";
import { UserVotingStatus, VoterInfo, VotingSession, VotingSessionConfig } from "./schemas/voting-session";
import { count } from "../util/fn-utils";
import { getClientState } from "./client-state";
import { authenticatedPut } from "../util/utils";

export const votingKeyPrefix = 'voting-';

function sessionKey(session: VotingSession | number): string {
  if (typeof session == 'number') {
    return votingKeyPrefix + (session.toString())
  }
  return votingKeyPrefix + (session.start.toString())
}

export async function startVotingSession(tx: WriteTransaction, config: VotingSessionConfig) {
  let session: VotingSession = {
    ...config,
    votesCast: {},
    votersInfo: { [config.ownerId]: { type: "known", status: UserVotingStatus.Joined, id: config.ownerId } },
    hidden: false,
    itemsThumbnails: {},
  }
  return authenticatedPut(tx, sessionKey(session), session);
}

export async function endVotingSession(tx: WriteTransaction, arg: { session: VotingSession, seconds?: number }) {
  const { session, seconds = 10 } = arg;
  const key = sessionKey(session);
  const latestData = await tx.get(key) as VotingSession;
  if (latestData) {
    const newEndTime = Date.now() + seconds * 1000;
    const allotedTime = (newEndTime - session.start) / 1000;
    const newData = {
      ...session,
      duration: allotedTime
    };
    return authenticatedPut(tx, key, newData);
  }
}

export async function updateVotingStatus(tx: WriteTransaction, arg: { session: VotingSession, status: Omit<UserVotingStatus, 'Unknown'>, userId?: string }) {
  const session = arg.session;
  const status = arg.status as UserVotingStatus;
  const userId = arg.userId;
  const key = sessionKey(session);
  const latestData = await tx.get(key) as VotingSession;
  const id = tx.clientID;
  if (latestData) {
    let voterInfo: VoterInfo = userId ?
      { type: "known", id: userId, status } :
      await getClientState(tx).then(state => ({ type: "anonymous", name: state.userInfo.name, avatar: state.userInfo.avatar!, status }));

    const newData: VotingSession = {
      ...session,
      votersInfo: {
        ...session.votersInfo,
        [userId ?? id]: voterInfo,
      }
    };
    return authenticatedPut(tx, key, newData);
  }
}

export async function hideVotingSession(tx: WriteTransaction, args: { sessionId: number, hide: boolean }) {
  const { sessionId, hide } = args;
  const key = sessionKey(sessionId);
  const latestData = await tx.get(key) as VotingSession;
  if (latestData) {
    return authenticatedPut(tx, key, { ...latestData, hidden: hide });
  }
}

export async function renameVotingSession(tx: WriteTransaction, args: { sessionId: number, newName: string }) {
  const { sessionId, newName } = args;
  const key = sessionKey(sessionId);
  const latestData = await tx.get(key) as VotingSession;
  if (latestData) {
    return authenticatedPut(tx, key, { ...latestData, title: newName });
  }
}

function validateUserInInSession(session: VotingSession, id?: string | null) {
  return !!id && session.votersInfo[id]?.status == UserVotingStatus.Joined;
}

function validateVotingIsInProgress(session: VotingSession) {
  return !session.duration || (session.start + session.duration * 1000) > Date.now();
}

function validateCanVoteOnObject(session: VotingSession, objectId: string) {
  return !session.elementsIds || session.elementsIds.includes(objectId);
}

export async function vote(tx: WriteTransaction, args: { session: VotingSession, userId?: string, elementId: string, vote: boolean, thumbnail?: string }): Promise<void> {
  const { elementId, vote, thumbnail, userId } = args;
  const key = sessionKey(args.session);
  const session = await tx.get(key) as VotingSession;
  const id = userId ?? tx.clientID;

  if (session) {
    let newSession = null;
    let votes = session.votesCast;
    let curVotes = votes[elementId] ?? [];

    // validations
    const valid =
      validateUserInInSession(session, id) &&
      validateVotingIsInProgress(session) &&
      validateCanVoteOnObject(session, elementId);
    // todo: check this user still has votes left to spend
    if (!valid) {
      return Promise.resolve();
    }

    if (vote == true) {
      let myvotes = count(id, curVotes);
      if (myvotes < session.maxVotesPerChoice) {
        newSession = {
          ...session,
          votesCast: {
            ...session.votesCast,
            [elementId]: [...curVotes, id]
          },
          itemsThumbnails: {
            ...session.itemsThumbnails,
            [elementId]: thumbnail ?? '',
          }
        };
      }
    } else {
      let index = curVotes.indexOf(id);
      if (index != -1) {
        newSession = {
          ...session,
          votesCast: {
            ...session.votesCast,
            [elementId]: session.votesCast[elementId].filter((_, i) => i != index)
          }
        };
      }
    }
    if (newSession != null) {
      return authenticatedPut(tx, key, newSession);
    }
  }
}