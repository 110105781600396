import { ReadTransaction, WriteTransaction } from "@workcanvascom/reflect";
import { getUnixTimestampUTC, validateSchema } from "../util/utils";
import { ClientState, clientStateSchema, VideoPresence } from "./schemas/client-state";
import { BoardUser } from "./schemas/board-user";

const colors = [
  // "#F5B53D", -> the first one is the defualt to the user
  { color: "#1973FF", textColor: "#FFFFFF" },
  { color: "#E03E1A", textColor: "#FFFFFF" },
  { color: "#FFB4C9", textColor: "#113357" },
  { color: "#00B875", textColor: "#FFFFFF" },
  { color: "#7549F5", textColor: "#FFFFFF" },
  { color: "#FFD748", textColor: "#113357" },
  { color: "#57CEF3", textColor: "#FFFFFF" },
  { color: "#E9478B", textColor: "#FFFFFF" },
  { color: "#9FDA8B", textColor: "#113357" },
  { color: "#C883FE", textColor: "#FFFFFF" },
  { color: "#F38528", textColor: "#FFFFFF" },
  { color: "#0012B8", textColor: "#FFFFFF" },
  { color: "#15BBB4", textColor: "#FFFFFF" },
  { color: "#2D69B2", textColor: "#FFFFFF" },
];

const avatars = ["🐶", "🐱", "🐭", "🐹", "🐰", "🦊", "🐻", "🐼", "🐻‍❄️", "🐨", "🐯", "🦁", "🐮", "🐷", "🐵", "🐣"];

export async function initClientState(
  tx: WriteTransaction,
  { defaultUserInfo, isReadOnlyUser }: { defaultUserInfo: BoardUser; isReadOnlyUser: boolean }
): Promise<void> {
  if (await tx.has(clientStateKey(tx.clientID))) {
    return;
  }
  let currentColorIndex = await tx.get("control.current-color-index");
  if (typeof currentColorIndex !== "number") {
    currentColorIndex = 0;
  }
  const { color, textColor } = colors[currentColorIndex];

  //this write transaction should not be authenticated for viewers
  await tx.set("control.current-color-index", (currentColorIndex + 1) % colors.length);
  await putClientState(tx, {
    clientState: {
      cursor: isReadOnlyUser
        ? null
        : {
            x: 0,
            y: 0,
          },
      userInfo: { ...defaultUserInfo, color, textColor },
      lastUpdated: getUnixTimestampUTC(),
      present: true,
    },
  });
}

export async function getClientState(tx: ReadTransaction, { id }: { id?: string } = {}): Promise<ClientState> {
  const clientId = clientStateKey(id ?? tx.clientID);
  const jv = await tx.get(clientId);
  if (!jv) {
    throw new Error("Expected clientState to be initialized already: " + clientId);
  }
  return validateSchema(clientStateSchema, jv);
}

export function putClientState(
  tx: WriteTransaction,
  { id, clientState }: { id?: string; clientState: ClientState },
): Promise<void> {
  return tx.set(clientStateKey(id ?? tx.clientID), clientState);
}

export async function setCursor(tx: WriteTransaction, { x, y }: { x: number; y: number }): Promise<void> {
  const { ...clientState } = await getClientState(tx);
  clientState.cursor = { x, y };
  clientState.lastUpdated = getUnixTimestampUTC();
  clientState.present = true;
  await putClientState(tx, { clientState });
}

export async function setClientViewport(
  tx: WriteTransaction,
  { position, scale }: { position: { x: number; y: number } | null; scale: number | null }
): Promise<void> {
  const clientState = await getClientState(tx);
  const newState = {
    ...clientState,
    lastUpdated: getUnixTimestampUTC(),
    present: true,
  };
  if (position) {
    newState.position = position;
  }
  if (scale) {
    newState.scale = scale;
  }
  await putClientState(tx, { clientState: newState });
}

export async function setVideoPresence(tx: WriteTransaction, videoPresence: Partial<VideoPresence>) {
  const clientState = await getClientState(tx);
  const now = getUnixTimestampUTC();
  if (videoPresence == null || videoPresence == undefined) {
    await putClientState(tx, {
      clientState: {
        ...clientState,
        lastUpdated: now,
        videoPresence: null,
      },
    });
  } else {
    const clients = await tx.scan({ prefix: clientStatePrefix }).values().toArray();
    let t = now;
    for (const v of clients) {
      const client = v as ClientState;
      if (!client.videoPresence) continue;
      t = Math.min(t, client.videoPresence.joinTimestamp);
    }
    const newState = {
      ...clientState,
      lastUpdated: now,
      videoPresence: Object.assign({}, clientState.videoPresence, videoPresence, { joinTimestamp: t })
    };
    await putClientState(tx, { clientState: newState });
  }
}

export async function setPresent(
  tx: WriteTransaction,
  { id, present }: { id: string; present: boolean }
): Promise<void> {
  const client = await getClientState(tx, { id });
  client.present = present;
  await putClientState(tx, { id, clientState: client });
}

export type UserDecoration = {
  color: string;
  textColor: string;
  avatar: string;
};
export function randomUserDecoration(): UserDecoration {
  const { color, textColor } = randomUserColor()
  return {
    color,
    textColor,
    avatar: avatars[randNumber(avatars.length - 1)],
  };
}

export function clientStateKey(id: string): string {
  return `${clientStatePrefix}${id}`;
}

export const clientStatePrefix = `client-state-`;

function randNumber(limit: number) {
  return Math.floor(Math.random() * limit);
}

export function randomUserColor() {
  return colors[randNumber(colors.length - 1)];
}

export function colorForIndex(index: number) {
  const indexInRange = index % colors.length;
  return colors[indexInRange];
}