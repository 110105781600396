import consts from "../../consts";
import { z } from "zod";
import { canvasElementBaseSchema } from "./canvas-element";
import { FontProperties } from "./textEnabled";


const textStyle = z.object({
  textColor: z.string(),
  fontSize: z.number(),
  align: z.enum(["left", "center", "right"]),
  valign: z.enum(["top", "middle", "bottom"]),
  fontProps: z.nativeEnum(FontProperties),
  font: z.string(),
});

export const defaultTextStyleForTable: z.infer<typeof textStyle> = {
  textColor: consts.DEFAULTS.TEXT_COLOR,
  fontSize: consts.DEFAULTS.FONTSIZE,
  align: "left",
  valign: "middle",
  fontProps: FontProperties.None,
  font: consts.DEFAULTS.FONT,
};

const rowOrColumn = z.object({ id: z.string(), size: z.number() });

export const tableElementSchemaInternal = z.object({
  type: z.literal("table"),
  rows: rowOrColumn.array(),
  cols: rowOrColumn.array(),
  fill: z.string(),
  stroke: z.string(),
  rotate: z.number().default(0),
  containedIds: z.array(z.string()).optional(),
});


export const tableCellSchema =
  z.object({
    text: z.string(),
    fill: z.string(),
    contentsIds: z.string().array(),
  })
    .merge(textStyle)
    .partial();

export const tableElementSchema =
  canvasElementBaseSchema
    .merge(textStyle)
    .merge(tableElementSchemaInternal);

export type TableTextShema = z.infer<typeof textStyle>;
export type TypeTableElement = z.infer<typeof tableElementSchema>;
export type TypeTableCell = z.infer<typeof tableCellSchema>;