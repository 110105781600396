const MOUSE_MOVE_THRESHOLD = 5;
const MIN_SIZE_NEW_SHAPES = 10;
const LINE_HEIGHT = 1.3;
const FOCUSED_ITEM_KEY = "focused";
const SET_POSITION_KEY = "set-position";
const SNAPPING_DISTANCE = 14;
const MIN_FONT_SIZE = 1;
const MAX_FONT_SIZE = 300;
const DEFAULT_BLACK = "#081B2F";

const MIN_WIDTH_TO_SHOW_PAYMENT_PAGE = 1440;

const STICKY_NOTE_COLORS = ["#FFD9CD", "#FFE7BC", "#BFEDDC", "#BFDCFF", "#E3D1FE", "#FFE1E9", "#E5E8EA", "#FFFFFF"];
const COLOR_PALETTE = [
  "#081B2F",
  "#E03E1A",
  "#F5B53D",
  "#00B875",
  "#1973FF",
  "#7549F5",
  "#E9478B",
  "#A5AAAD",
  "#F38528",
  "#FFD748",
  "#9FDA8B",
  "#57CEF3",
  "#C883FE",
  "#FFB4C9",
];

const TEAM_PICKER_COLOR_PALETTE = [
  "#F5B53D",
  "#FFB4C9",
  "#00B875",
  "#7549F5",
  "#57CEF3",
  "#E9478B",
  "#F38528",
  "#FFD748",
  "#C883FE",
  "#9FDA8B",
];
const COLOR_PALETTE_INCLUDING_WHITE_TRANSPARENT = [
  ...COLOR_PALETTE.slice(0, 7),
  "white",
  ...COLOR_PALETTE.slice(7),
  "transparent",
];

const MINDMAP_COLOR_PALETTE = [
  "#1973FF",
  "#7549F5",
  "#E9478B",
  "#E34D2A",
  "#F38528",
  "#F5B53D",
  "#FFD748",
  "#9FDA8B",
  "#00B875",
];

const ORG_CHART_COLOR_PALETTE = [
  "#081B2F",
  "#E03E1A",
  "#F5B53D",
  "#00B875",
  "#1973FF",
  "#7549F5",
  "#E9478B",
  "#A5AAAD",
  "#F38528",
  "#F0C630",
  "#9FDA8B",
  "#57CEF3",
  "#C883FE",
  "#FFA6BF",
];

const MENU_ITEMS = {
  SHAPE: "shape",
  CONNECTOR: "connector",
  TEXT_BLOCK: "text-block",
  MINDMAP: "mindmap",
  PEN: "pen",
  STICKY_NOTE: "sticky-note",
  FRAME: "frame",
  Hand: "hand",
};

const CANVAS_ELEMENTS = {
  SHAPE: "shape",
  CONNECTOR: "connector",
  TEXT_BLOCK: "textBlock",
  DRAWING: "drawing",
  STICKY_NOTE: "stickyNote",
  FILE: "file",
  TASK_CARD: "taskCard",
  FRAME: "frame",
  COMMENT: "comment",
  MINDMAP: "mindmap",
  MINDMAP_ORG_CHART: "mindmapOrgChart",
  ORG_CHART: "orgChartRoot",
  ORG_CHART_NODE: "orgChartNode",
  INTEGRATION: "integrationItem",
  CARD_STACK: "cardStack",
  LIVE_INTEGRATION: "liveIntegration",
  TIMELINE: "timeline",
  TABLE: "table",
} as const;

type TypeCanvasElement = typeof CANVAS_ELEMENTS[keyof typeof CANVAS_ELEMENTS];

const ELEMENT_TRAITS = {
  COLOR: "color",
  STROKE_COLOR: "stroke-color",
  LINE_WIDTH: "line-width",
  CONNECTOR_TYPE: "line-type",
  SHAPE_TYPE: "shape-type",
  ENDPOINT_TYPE: "endpoint-type",
  TEXT_SIZE: "text-size",
  TEXT_ALIGN: "text-align",
  TEXT_STYLE: "text-style",
  TEXT_COLOR: "text-color",
};

const TEXT_SIZES = {
  LARGE: "large",
  MEDIUM: "medium",
  SMALL: "small",
};

const CONTEXT_MENU_ELEMENT = {
  COPY: "Copy",
  CUT: "Cut",
  DELETE: "Delete",
  ADD_LINK: "Link to...",
  EDIT_LINK: "Edit link",
  LOCK: "Lock",
  UNLOCK: "Unlock",
  MOVE_TO_FRONT: "Bring to front",
  MOVE_TO_BACK: "Send to back",
  GROUP: "Group",
  UNGROUP: "Ungroup",
  FLIP_HORIZONTALLY: "Flip horizontally",
  FLIP_VERTICALLY: "Flip vetically",
  SET_AS_THUMBNAIL: "Set as thumbnail",
  EXPORT: "Export",
  EXPORT_IMAGE: "Export as Image",
  EXPORT_PDF: "Export as PDF",
  CLEAR_THUMBNAIL: "Clear thumbnail",
  CREATE_MONDAY_ITEM: "Create monday item",
  CREATE_MONDAY_SUBMENU_ITEM: "Create item",
  CREATE_MONDAY_SUBMENU_SUBITEM: "Create subitem",
};

const CONTEXT_MENU_STAGE = {
  PASTE: "Paste",
  ZOOM_IN: "Zoom in",
  ZOOM_OUT: "Zoom out",
  ADD_TEXT: "Add text",
  ADD_STICKY: "Add sticky note",
  ADD_SHAPE: "Add shape",
  ADD_MINDMAP: "Add mind map",
  ADD_COMMENT: "Add comment",
};

const WIDTH_SIZES = {
  LARGE: "large",
  SMALL: "small",
};

const SHAPES = {
  RECT: "rect",
  RECT_ROUNDED: "rect-rounded",
  DIAMOND: "diamond",
  CIRCLE: "circle",
  TRIANGLE: "triangle",
  HEXAGON: "hexagon",
} as const;

type TypeShape = typeof SHAPES[keyof typeof SHAPES];

const DEFAULTS = {
  STICKY_NOTE_HEIGHT: 200,
  STICKY_NOTE_FONT_SIZE: 16,
  SHAPE_FONTSIZE: 16,
  TEXT: "Add text",
  CARD_TEXT: "Type something...",
  CARD_WIDTH: 325,
  CARD_HEIGHT: 160,
  MONDAY_CARD_WIDTH: 482, // width is fixed for all cards
  MONDAY_CARD_HEIGHT: 214, // minimum height for monday card. can be more
  FONT: "Poppins",
  FONTSIZE: 16,
  ROUNDED_RECT_RADIUS: 15,
  TEXT_ALIGN: "center",
  TEXT_COLOR: DEFAULT_BLACK,
  TEXT_PLACEHOLDER_COLOR: "#848199",
  MINDMAP_ROOT_STROKE_COLOR: "#113255",
  MINDMAP_ROOT_SIZE: { width: 170, height: 65 },
  MINDMAP_NODE_SIZE: { width: 80, height: 30 },
  MINDMAP_PLACEHOLDER: "Type something",
  MINDMAP_NODE_VERTICAL_PADDING: 20,
  MINDMAP_NODE_HORIZONTAL_PADDING: 8,
  MINDMAP_COLOR: "#1973FF",
  MINDMAP_CONNECTOR_CHILD_PADDING: 15,
  MINDMAP_ADD_NODE_BUTTON_PADDING: 40,
};

const TRACKING_CATEGORY = {
  CANVAS_ACTION: "canvas-action",
  ADMIN_ACTION: "admin-actions",
  SIDE_MENU: "side-menu",
  USER_PRESENCE: "user-presence",
  BILLING: "billing",
  ACQUISITION: "acquisition",
  PAGE_VIEWS: "page-views",
  PROMOTIONS: "promotions",
  TEMPLATES: "templates",
  OPEN_SESSION: "open-session",
  CONNECTIVITY: "connectivity",
  SIGNUP: "signup",
  ONBOARDING: "onboarding",
  FILES: "files",
  ACCEPT_INVITATION: "accept_invitation",
  WALKTHROUGH: "walkthrough",
  COLLABORATION: "collaboration",
  INTEGRATIONS: "integrations",
  TEAM_ACTION: "team-action",
  PROJECT_ACTION: "project-action",
  VIDEO_CALLS: "video-calls",
  ORG_CHART: "org-chart",
  VOTING: "voting",
  INVITE_ACTION: "invite-action",
  RESTRICTED_ACTION: "restricted-action",
  MIRO_MIGRATION: "miro-migration",
};

const GA_CONVERSION_TYPES = {
  HARD_SIGNUP: "hard-signup",
  UPGRADE_CTA: "upgrade-cta",
  NEW_ITEM_PLACED: "new-item-placed",
  INVITE_LINK_COPIED: "invite-link-copied",
  CANVAS_LIMIT_REACHED: "canvas-limit-reached",
  RETURNING_USER: "returning-user",
  PROMOTION_CLICKED: "promotion-clicked",
  PAID_PLAN_SELECTED: "paid-plan-selected",
  PAYMENT_COMPLETED: "payment-completed",
  TEAM_SIGNUP: "team-signup",
};

const PRESENT_USER_THRESHOLD_IN_SEC = 5 * 60;
const IDLE_USER_THRESHOLD_IN_SEC = 2 * 60;
const MAX_ONLINE_BOARD_USERS = 10;

const FEATURE_NAMES = {
  MAX_ALLOWED_CANVASES: "max_allowed_canvases",
  MAX_ALLOWED_COLLABORATORS: "max_allowed_collaborators",
  ANONYMOUS_ENABLED: "anonymous_enabled",
  TEMPLATES: "templates",
  EXPORT: "export",
  VIDEO_CALLS: "video_calls",
  MONDAY_INTEGRATION: "monday_integration",
  CANVAS_ELEMENTS_LIMIT: "max_allowed_canvas_elements",
  APPLY_VIEWER_RESTRICTED: "apply_viewer_restricted",
  CREATE_TEMPLATE_ENABLED: "create_template_enabled",
};

export enum Plan {
  basic = 1,
  pro = 2,
  team = 3,
}

const COOKIE_NAMES = {
  CANVAS_PLAN_ID: "canvas_plan_id",
  BOARD_TEMPLATE: "canvas_board_template",
  SOURCE: "source",
};

const MIN_ELEMENTS_TO_OPTIMIZE_DRAG = 10;

const LOCAL_STORAGE_KEYS = {
  PROMOTIONS_DISMISSED: "promotions-dismissed",
};

const SESSION_STORAGE_KEYS = {
  FIRST_ACCESS: "first-access",
  SHOW_TEMPLATES_ON_START: "show-templates-on-start",
  SHOW_ORG_CHART_TEMPLATES_ON_START: "show-org-chart-templates-on-start",
  TEMPLATE_CHOSEN_OPTIONS: "template-options",
  CREATED_TEMPLATE_DATA: "created-template-data",
};

const STAGE_ZOOM = {
  MIN: 0.01,
  MAX: 4,
};

const INDEXED_DB = {
  OBJECT_STORES: {
    FILES_PREVIEWS: "files-previews",
  },
  INDEXEDS: {
    UPPY_FILE_ID: "uppyFileId",
  },
};

const PRICING = {
  PRO: {
    VALUE: 10,
  },
};

const MAIL_TRIGGERS = {
  UPGRADE_PLAN: "start_checkout",
};

const ALL_TEMPLATES_TAG_ID = -1;
const MY_TEMPLATES_TAG_ID = -2;
const ACCOUNT_TEMPLATES_TAG_ID = -3;
const SYNCED_TEMPLATES_ID = 1006;

const Experiment = {
  CHECKOUT: "checkout",
};

const INTEGRATIONS_ITEMS_LIMIT = 30;
const INTEGRATIONS_ITEMS_QUERY_LIMIT = 50;

const SHOW_CURSORS_USER_LIMIT = 20;

export type { TypeCanvasElement, TypeShape };

export default {
  MENU_ITEMS,
  CANVAS_ELEMENTS,
  ELEMENT_TRAITS,
  TEXT_SIZES,
  CONTEXT_MENU_ELEMENT,
  CONTEXT_MENU_STAGE,
  WIDTH_SIZES,
  SHAPES,
  DEFAULTS,
  PRESENT_USER_THRESHOLD_IN_SEC,
  IDLE_USER_THRESHOLD_IN_SEC,
  MAX_ONLINE_BOARD_USERS,
  TRACKING_CATEGORY,
  FEATURE_NAMES,
  GA_CONVERSION_TYPES,
  COOKIE_NAMES,
  MIN_ELEMENTS_TO_OPTIMIZE_DRAG,
  LOCAL_STORAGE_KEYS,
  SESSION_STORAGE_KEYS,
  STAGE_ZOOM,
  MOUSE_MOVE_THRESHOLD,
  MIN_SIZE_NEW_SHAPES,
  LINE_HEIGHT,
  INDEXED_DB,
  STICKY_NOTE_COLORS,
  COLOR_PALETTE,
  TEAM_PICKER_COLOR_PALETTE,
  COLOR_PALETTE_INCLUDING_WHITE_TRANSPARENT,
  MINDMAP_COLOR_PALETTE,
  ORG_CHART_COLOR_PALETTE,
  FOCUSED_ITEM_KEY,
  SNAPPING_DISTANCE,
  SET_POSITION_KEY,
  PRICING,
  MAIL_TRIGGERS,
  MIN_FONT_SIZE,
  MAX_FONT_SIZE,
  ALL_TEMPLATES_TAG_ID,
  MY_TEMPLATES_TAG_ID,
  ACCOUNT_TEMPLATES_TAG_ID,
  SYNCED_TEMPLATES_ID,
  Experiment,
  INTEGRATIONS_ITEMS_LIMIT,
  INTEGRATIONS_ITEMS_QUERY_LIMIT,
  SHOW_CURSORS_USER_LIMIT,
  MIN_WIDTH_TO_SHOW_PAYMENT_PAGE,
};
