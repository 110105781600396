import { z } from "zod";
import { userSchema } from "./user";
import { boardSchema } from "./board";
import { accountSchema } from "./account";
import { promotionScheman } from "./promotion";
import { teamSchema, pendingUserPermissionSchema } from "./team";
import LoadedState from "frontend/state/loadedState";
import { Reflect } from "@workcanvascom/reflect/client";

export const usersSchema = z.map(z.number(), userSchema);

export const appStateSchema = z.object({
  isLoggedIn: z.boolean(),
  user: userSchema.nullable(),
  userState: z.nativeEnum(LoadedState),
  account: accountSchema.nullable(),
  boardsState: z.nativeEnum(LoadedState),
  boards: z.array(boardSchema),
  usersState: z.nativeEnum(LoadedState),
  activePromotion: promotionScheman.nullable(),
  pricingPromotion: promotionScheman.nullable().optional(),
  accountReps: z.instanceof(Reflect).optional(),
  teams: z.array(teamSchema),
  pendingUsersPermissions: z.array(pendingUserPermissionSchema).default([]),
});

export type AppState = z.infer<typeof appStateSchema>;
export type Users = z.infer<typeof usersSchema>;
