import ModalContainer from "../../modals/modal-container";
import StyledButton from "../styled-button";
import { useSSE } from "../../hooks/use-sse";
import style from "./sse-modal.module.css";
import tracking from "../../tracking";
import { useAtomValue } from "jotai";
import { syncServiceAtom } from "../../../state-atoms";
import useReflectSanity from "../../hooks/use-reflect-sanity";

export default function AppNeedsReloadModal() {
  const { forceReload } = useSSE();
  const syncService = useAtomValue(syncServiceAtom);
  const reflectErrorReason = useReflectSanity(syncService?.getReplicache(), syncService?.isReadOnly() ?? true);

  if (!reflectErrorReason && !forceReload) {
    return null;
  }

  function onUpdateClick() {
    tracking.trackEvent("utils", "app_force_reload_clicked", process.env.DEPLOY_VERSION);
    window.location.reload();
  }

  return (
    <div className={"curtain"}>
      <ModalContainer onDismiss={() => {}} showCloseButton={false}>
        <div className={style.container}>
          <img src={"/images/sse-block-icon.png"} alt="Update" />
          <span className={style.title}>
            A new WorkCanvas version is <br />
            available.
          </span>
          <span className={style.subtitle}>Please reload the page to get the latest version.</span>
          <StyledButton title="Reload" customStyle={{ width: 120 }} onClick={onUpdateClick} />
        </div>
      </ModalContainer>
    </div>
  );
}
