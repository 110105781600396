import { ReadonlyJSONObject, ReadTransaction, WriteTransaction } from "@workcanvascom/reflect";
import {
  canvasMetadataPrefix,
  getUnixTimestampUTC,
  validateSchema,
  authenticatedPut,
  authenticatedDelete,
  createElementId,
} from "../util/utils";
import { getElement } from "./canvas-element";
import {
  FileMetadataType,
  FileUnlimitedSlot,
  Metadata,
  metaDataSchema,
  Tag,
  TagType,
} from "./schemas/metadata";

function key(id: string): string {
  return `${canvasMetadataPrefix}${id}`;
}

async function getMetadata(tx: ReadTransaction, id: string): Promise<Metadata | null> {
  const jv = await tx.get(key(id));
  if (!jv) {
    console.log(`Specified metadata ${id} not found.`);
    return null;
  }
  return validateSchema(metaDataSchema, jv);
}

export async function changeMetadata(tx: WriteTransaction, info: { id: string; value: Metadata }): Promise<void> {
  const value = await getMetadata(tx, info.id);
  if (value) {
    await putMetadata(tx, { id: info.id, value: { ...value, ...info.value } });
  }
}

export function putMetadata(tx: WriteTransaction, { id, value }: { id: string; value: Metadata }): Promise<void> {
  const next = { ...(value as ReadonlyJSONObject), lastModifiedTimestamp: getUnixTimestampUTC() };
  return authenticatedPut(tx, key(id), next);
}

// should I return a promise of all tx.put promises?
export async function putManyMetadata(tx: WriteTransaction, items: { id: string; value: Metadata }[]) {
  const now = getUnixTimestampUTC();
  await Promise.all(
    items.map((item) => {
      item.value.lastModifiedTimestamp = now;
      return authenticatedPut(tx, key(item.id), item.value);
    })
  );
}

export async function deleteMetadata(tx: WriteTransaction, { id }: { id: string }): Promise<boolean> {
  return authenticatedDelete(tx, key(id));
}

export async function mergeMetadata(tx: WriteTransaction, rawMetadata: [string, Metadata][]): Promise<void> {
  return (async function () {
    const existingKeys = (await tx.scan({ prefix: canvasMetadataPrefix }).keys().toArray()).map((key) =>
      key.substring(canvasMetadataPrefix.length)
    );
    for (const [id, value] of rawMetadata) {
      if (value && !existingKeys.includes(id)) {
        if (value.type == FileMetadataType) {
          const newValue = { ...value, limitedSlot: FileUnlimitedSlot };
          await authenticatedPut(tx, key(id), newValue);
        } else {
          await authenticatedPut(tx, key(id), value);
        }
      }
    }
  })();
}

export function createTag(name: string, color: string) {
  return {
    id: createElementId(),
    value: {
      type: TagType,
      name,
      color,
      order: getUnixTimestampUTC(),
    } as Tag,
  };
}

export const ThumbnailIdKey = `canvas_thumbnail_id`;

// get thumbnail id metadata
export async function getThumbnailId(tx: ReadTransaction): Promise<string | null> {
  const elementId = await tx.get(ThumbnailIdKey);
  if (elementId && typeof elementId === "string") {
    // make sure the element exists
    const element = await getElement(tx, elementId);
    if (element && !element.hidden) {
      return elementId;
    }
  }
  return null;
}

// set thumbnail id metadata
export async function setThumbnailId(tx: WriteTransaction, id: string): Promise<void> {
  await authenticatedPut(tx, ThumbnailIdKey, id);
}

export async function clearThumbnailId(tx: WriteTransaction): Promise<void> {
  await authenticatedDelete(tx, ThumbnailIdKey);
}
