import { initUserData } from "frontend/services/initService";
import useStateValue from "frontend/state/value";
import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { TipName } from "shared/datamodel/schemas";
import { accountAtom, userAtom, userTipsAtom } from "state-atoms/users-atoms";
import { setCookie } from "../utils/cookie-utils";
import LoadedState from "frontend/state/loadedState";
import { generateRedirectURL } from "frontend/utils/url-utils";

export function redirectToAuth() {
  const redirectURL = generateRedirectURL(process.env.MONDAY_AUTH_REDIRECT_URL);
  window.location.href = redirectURL;
}

export function getSignupURL(callbackURL: string = "") {
  // TODO: add callback in cookie
  const url = `${process.env.APP_URL}/auth/signup`;
  return url;
}

export default function useAuthentication(required: boolean) {
  const [{ isLoggedIn, user, account, userState }, dispatch] = useStateValue();
  const [shouldAuthenticate, setShouldAuthenticate] = useState<boolean | null>(null);

  const setUser = useSetAtom(userAtom);
  const setUserTips = useSetAtom(userTipsAtom);
  const setAccount = useSetAtom(accountAtom);

  useEffect(() => {
    async function init() {
      await initUserData(dispatch);
    }

    if (isLoggedIn) {
      setCookie("is_logged_in", "true");
      setShouldAuthenticate(false);
    } else {
      setCookie("is_logged_in", "false");
      init().catch(() => setShouldAuthenticate(required));
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (userState != LoadedState.loading) {
      setUser(user);
      if (user) {
        setUserTips((tips) => new Set([...tips, ...user.tipsSeen]));
      } else {
        // anonymous users don't see the walkthrough
        setUserTips(new Set([TipName.canvasWalkthrough]));
      }
    }
  }, [userState]);

  useEffect(() => {
    setAccount(account);
  }, [account]);

  useEffect(() => {
    if (shouldAuthenticate == true) {
      redirectToAuth();
    }
  }, [shouldAuthenticate]);

  return {
    isReady: shouldAuthenticate === false,
    isLoggedIn,
    triggerAuthentication: () => setShouldAuthenticate(true),
  };
}
