import { CloseIcon } from "frontend/ui-components/svg-shapes";
import useOutsideRef from "frontend/utils/click-outside-handler";
import { useRef } from "react";
import style from "./modal-container.module.css";

export default function ModalContainer({
  children,
  onDismiss,
  disableDetection,
  showCloseButton = true,
}: {
  children: any;
  onDismiss: () => void;
  disableDetection?: boolean;
  showCloseButton?: boolean;
}) {
  const modalRef = useRef(null);

  useOutsideRef(modalRef, onDismiss, { disableDetection });

  return (
    <div className={style.container} ref={modalRef}>
      {showCloseButton && (
        <div className={style.close} onClick={onDismiss}>
          <CloseIcon color="#848199" />
        </div>
      )}
      {children}
    </div>
  );
}
