import { z } from "zod";
import { canvasElementBaseSchema } from "./canvas-element";

export const assigneeSchema = z.object({
  id: z.number(),
  name: z.string(),
  email: z.string(),
  thumbnail: z.string(),
});

export const taskCardSchemaInternal = z.object({
  type: z.literal("taskCard"),
  height: z.number().optional(),
  externalId: z.string().optional(),
  title: z.string(),
  description: z.string(),
  color: z.string(),
  creationTime: z.number(),
  dueDate: z.number().default(0),
  assignees: z.array(assigneeSchema).optional(),
  tags: z.array(z.string()).optional(),
});

export const taskCardSchema = canvasElementBaseSchema.merge(taskCardSchemaInternal);

export type Assignee = z.infer<typeof assigneeSchema>;
export type TaskCard = z.infer<typeof taskCardSchema>;
