import { loadBoard } from "frontend/services/boardsService";
import useStateValue from "frontend/state/value";
import { useEffect, useState } from "react";
import { Board, BoardPermission } from "shared/datamodel/schemas/board";
import useAuthentication from "./use-authentication";
import { useTeam } from "./use-team";
import { Team } from "shared/datamodel/schemas/team";
import { Project } from "shared/datamodel/schemas/project";

export default function useBoard(documentId: string, authRequired = false) {
  const [{ boards, teams, account }, dispatch] = useStateValue();
  const [board, setBoard] = useState<Board | null>(null);
  const [ loaded, setIsLoaded ] = useState(false);
  const [ templateElements, setTemplateElements ] = useState<Record<string, any> | null>(null);
  const [ failedLoading, setFailedLoading ] = useState(false);
  const { isReady, triggerAuthentication } = useAuthentication(authRequired);
  const { myTeams, getTeam } = useTeam();

  useEffect(() => {
    if (!isReady) {
      return;
    }
    const board = boards.find((board) => board.documentId === documentId);
    if (board) {
      setBoard(board);
    } else if (!loaded) {
      loadBoard(dispatch, documentId)
          .then(({ board, templateElements }) => {
            setTemplateElements(templateElements);
            setIsLoaded(true);
            setBoard(board);
          })
        .catch((e) => {
          if (e.response?.status == 401) {
            triggerAuthentication();
          } else {
            setFailedLoading(true);
          }
        });
    } else {
      setFailedLoading(true);
    }
  }, [isReady, boards]);

  function getBoardTeamAndProject(): { team: Team, project: Project | null } {
    let boardTeam = null;
    let boardProject = null;
    if (board?.projectId) {
      for (const team of myTeams) {
        boardProject = team.projects?.find((project) => project.id === board?.projectId);
        if (boardProject) {
          boardTeam = team;
          break;
        }
      }
    }
    boardTeam ??= getTeam(board?.teamId);
  return { team: boardTeam, project: boardProject };
  }

  function checkAccessByAccount() {
    if (board?.accountId === account?.id) {
      return true;
    }
  }

  function hasAccessByTeamOrProject() {
    if (!checkAccessByAccount()) {
      return false;
    }
    if (!board?.projectId && !board?.teamId) {
      return true;
    }
    if (board?.projectId) {
      return teams.some((t) => t.projects?.some((p) => p.id === board.projectId));
    }

    if (board?.teamId) {
      return teams.some((t) => t.id === board.teamId);
    }

    return false;
  }

  function hasPublicAccess() {
    return board?.permission === BoardPermission.public || board?.permission === BoardPermission.publicReadOnly;
  }

  return { isReady, board, failedLoading, templateElements , getBoardTeamAndProject, hasAccessByTeamOrProject, hasPublicAccess};
}
