import "../styles/globals.css";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/image-editor/dist/style.css";

import { useAtomValue } from "jotai";
import AppLoader from "../frontend/loader/app-loader";
import { StateProvider } from "../frontend/state";
import { isBusyAtom, userAtom } from "../state-atoms";
import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import { addTestUtils } from "../frontend/utils/test-utils";
import { PortalID } from "frontend/modal/usePortal";
import AppNeedsReloadModal from "../frontend/ui-components/sse/app-needs-reload-modal";

function MyApp({ Component, pageProps }) {
  const isBusy = useAtomValue(isBusyAtom);
  const user = useAtomValue(userAtom);

  useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user.id, accountId: user.account?.id, email: user.email });
      addTestUtils(user.account.id);
    }
  }, [user]);

  return (
    <StateProvider>
      <Component {...pageProps} />
      <div className={"checkout"} id={PortalID.Checkout} />
      <div className={"curtain"} hidden={!isBusy}>
        <AppLoader />
      </div>
      <AppNeedsReloadModal />
    </StateProvider>
  );
}

export default MyApp;
