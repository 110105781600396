import { z } from "zod";
import { IntegrationType, MondayIntegrationType } from "../../integrations/integration";
import { canvasElementBaseSchema } from "./canvas-element";

const integrationItemInternal = z.object({
  type: z.literal("integrationItem"),
  integrationId: z.string(),
  integrationType: z.nativeEnum(IntegrationType),
  configuration: z.any(),
});

const mondayIntegrationConfiguration = z.object({
  boardId: z.string(),
  type: z.nativeEnum(MondayIntegrationType),
  columns: z.array(z.object({ id: z.string(), type: z.string() })).default([]),
  isSubitemsBoard: z.boolean().optional(),
  parentBoardId: z.string().optional(),
  columnMapping: z
    .object({
      selfId: z.string(),
      parentId: z.string(),
      jobTitle: z.string(),
      email: z.string(),
      status: z.string(),
    })
    .optional(),
});

export const integrationItemSchema = canvasElementBaseSchema.merge(integrationItemInternal);

export type IntegrationItem = z.infer<typeof integrationItemSchema>;
export type MondayIntegrationConfiguration = z.infer<typeof mondayIntegrationConfiguration>;
